import {Injectable} from '@angular/core';
import {UserDto} from '@api/models';
import {UserControllerService} from '@api/services';
import {Model, ToastService} from '@base';
import {TranslateService} from '@ngx-translate/core';
import {catchError, Observable, of, tap, throwError} from 'rxjs';

@Injectable()
export class UserService {
    private usersModel = Model.create<UserDto[]>([]);
    public readonly users$ = this.usersModel.data$;

    constructor(
        private userControllerService: UserControllerService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {
    }

    public getUsers(): Observable<UserDto[]> {
        return this.userControllerService.getAllUsers().pipe(tap((data) => this.usersModel.set(data)));
    }
    public getUsersToExcel(): Observable<Blob> {
        return this.userControllerService.getUsersToExcel().pipe(tap((data) => {
        }),catchError((err, caught) => {
            this.toastService.showErrorToast(this.translateService.instant(err.status == 404 ? 'toast.downloadEmpty' :'toast.downloadFailed'));
            return throwError(err);
        }));
    }

    public createUser(user: UserDto): Observable<string> {
        return this.userControllerService.createUser({body: user}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(this.translateService.instant('toast.newUserSuccess'));
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.newUserFailed'));
                return throwError(error);
            })
        );
    }
    public updateUser(user: UserDto): Observable<UserDto> {
        return this.userControllerService.updateUser({body: user}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(this.translateService.instant('toast.updateUserSuccess'));
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.updateUserFailed'));
                return throwError(error);
            })
        );
    }

    public removeUser(user: UserDto): Observable<void> {
        if (!user.id) {
            return of();
        }

        return this.userControllerService.deleteUser({id: user.id}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(
                    this.translateService.instant('toast.deletedUserSuccess', {
                        firstName: user.firstName,
                        lastName: user.lastName
                    })
                );
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.deleteUserFailed' , {
                    firstName: user.firstName,
                    lastName: user.lastName
                }));
                return throwError(error);
            })
        );
    }

    public getUser(userId: string): Observable<UserDto> {
        return this.userControllerService.getUserById({id: userId});
    }
}
