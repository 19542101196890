import {Injectable} from '@angular/core';
import {BadgeDto} from '@api/models';
import {BadgeControllerService} from '@api/services';
import {Model, ToastService} from '@base';
import {TranslateService} from '@ngx-translate/core';
import {catchError, Observable, of, tap, throwError} from 'rxjs';

@Injectable()
export class BadgeService {
  private badgesModel = Model.create<BadgeDto[]>([]);
  public readonly badge$ = this.badgesModel.data$;

  constructor(
      private badgeControllerService: BadgeControllerService,
      private translateService: TranslateService,
      private toastService: ToastService
  ) {
  }

  public getBadges(): Observable<BadgeDto[]> {
    return this.badgeControllerService.getAllBadges().pipe(tap((data) => this.badgesModel.set(data)));
  }

  public createBadge(badge: BadgeDto): Observable<string> {
    return this.badgeControllerService.createBadge({body: badge}).pipe(
        tap(() => {
          this.toastService.showSuccessToast(this.translateService.instant(badge.id !== null ? 'toast.updateBadgeSuccess' : 'toast.newBadgeSuccess'));
        }),
        catchError((error) => {
          this.toastService.showErrorToast(this.translateService.instant(badge.id !== null ? 'toast.updateBadgeFailed' : 'toast.newBadgeFailed'));
          return throwError(error);
        })
    );
  }

  public removeBadge(badge: BadgeDto): Observable<void> {
    if (!badge.id) {
      return of();
    }

    return this.badgeControllerService.deleteBadge({id: badge.id}).pipe(
        tap(() => {
          this.toastService.showSuccessToast(
              this.translateService.instant('toast.deletedBadgeSuccess', {
                badgeName: badge.name,
              })
          );
        }),
        catchError((error) => {
          this.toastService.showErrorToast(this.translateService.instant('toast.deleteBadgeFailed', {
            badgeName: badge.name,
          }));
          return throwError(error);
        })
    );
  }
}
