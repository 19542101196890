import {Injectable} from '@angular/core';
import {FormTemplateCardDto, FormTemplateDto} from '@api/models';
import {Model, ToastService} from '@base';
import {FormTemplateControllerService} from '@api/services/form-template-controller.service';
import {TranslateService} from '@ngx-translate/core';
import {catchError, Observable, of, tap, throwError} from 'rxjs';
import {FeedControllerService} from "@api/services/feed-controller.service";

@Injectable()
export class FormTemplateService {
    private formTemplateCardsModel = Model.create<FormTemplateCardDto[]>([]);
    public readonly formTemplateCard$ = this.formTemplateCardsModel.data$;

    constructor(
        private formTemplateControllerService: FormTemplateControllerService,
        private translateService: TranslateService,
        private toastService: ToastService,
    ) {
    }

    public getFormTemplates(): Observable<FormTemplateCardDto[]> {
        return this.formTemplateControllerService.getFormTemplateCards().pipe(tap((data) => this.formTemplateCardsModel.set(data)));
    }

    public getFormsFromTemplateToExcel(id: string): Observable<Blob> {
        return this.formTemplateControllerService.getFormsFromTemplateToExcel({id}).pipe(tap((data) => {
        }), catchError((err, caught) => {
            this.toastService.showErrorToast(this.translateService.instant(err.status == 404 ? 'toast.downloadEmpty' :'toast.downloadFailed'));
            return throwError(err);

        } ));
    }
    public getFormsToExcelsInZip(): Observable<Blob> {
        return this.formTemplateControllerService.getFormsToExcelsInZip().pipe(tap((data) => {
        }),catchError((err, caught) => {
            this.toastService.showErrorToast(this.translateService.instant(err.status == 404 ? 'toast.downloadEmpty' :'toast.downloadFailed'));
            return throwError(err);
        }));
    }

    public createForm(formTemplate: FormTemplateDto): Observable<string> {
        return this.formTemplateControllerService.createFormTemplate({body: formTemplate}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(this.translateService.instant(formTemplate.id !== null ? 'toast.updateFormSuccess' : 'toast.newFormSuccess'));
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant(formTemplate.id !== null ? 'toast.updateFormFailed' : 'toast.newFormFailed'));
                return throwError(error);
            })
        );
    }

    public updateTemplate(formTemplate: FormTemplateDto): Observable<FormTemplateDto> {
        return this.formTemplateControllerService.updateFormTemplate({body: formTemplate}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(this.translateService.instant('toast.updateFormSuccess'));
            }),
            catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.updateFormFailed'));
                return throwError(error);
            })
        );
    }

    public removeFormTemplate(formTemplate: FormTemplateDto): Observable<void> {
        if (!formTemplate.id) {
            return of();
        }

        return this.formTemplateControllerService.deleteFormTemplateById({id: formTemplate.id}).pipe(
            tap(() => {
                this.toastService.showSuccessToast(
                    this.translateService.instant('toast.deletedFormTemplateSuccess', {
                        formTemplateName: formTemplate.name
                    })
                );
            }), catchError((error) => {
                this.toastService.showErrorToast(this.translateService.instant('toast.deletedFormTemplateFailed', {
                    formTemplateName: formTemplate.name
                }));
                return throwError(error);
            })
        );
    }
}
