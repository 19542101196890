import {Injectable} from '@angular/core';
import {AnnouncementDto} from '@api/models';
import {AnnouncementControllerService} from '@api/services';
import {Model, ToastService} from '@base';
import {TranslateService} from '@ngx-translate/core';
import {catchError, Observable, of, tap, throwError} from 'rxjs';

@Injectable()
export class AnnouncementService {
	private announcementsModel = Model.create<AnnouncementDto[]>([]);
	public readonly announcement$ = this.announcementsModel.data$;

	constructor (
		private announcementControllerService: AnnouncementControllerService,
		private translateService: TranslateService,
		private toastService: ToastService
	) {
	}

	public getAnnouncements (): Observable<AnnouncementDto[]> {
		return this.announcementControllerService.getAnnouncements().pipe(tap((data) => this.announcementsModel.set(data)));
	}

	public createAnnouncement (announcement: AnnouncementDto): Observable<string> {
		return this.announcementControllerService.createAnnouncement({body: announcement}).pipe(
			tap(() => {
				this.toastService.showSuccessToast(this.translateService.instant('toast.newAnnouncementSuccess'));
			}),
			catchError((error) => {
				this.toastService.showErrorToast(this.translateService.instant('toast.newAnnouncementFailed'));
				return throwError(error);
			})
		);
	}

	public updateAnnouncement (announcement: AnnouncementDto): Observable<AnnouncementDto> {
		return this.announcementControllerService.updateAnnouncement({body: announcement}).pipe(
			tap(() => {
				this.toastService.showSuccessToast(this.translateService.instant('toast.updateAnnouncementSuccess'));
			}),
			catchError((error) => {
				this.toastService.showErrorToast(this.translateService.instant('toast.updateAnnouncementFailed'));
				return throwError(error);
			})
		);
	}

	public removeAnnouncement (announcement: AnnouncementDto): Observable<void> {
		if (!announcement.id) {
			return of();
		}

		return this.announcementControllerService.deleteAnnouncement({id: announcement.id}).pipe(
			tap(() => {
				this.toastService.showSuccessToast(
					this.translateService.instant('toast.deletedAnnouncementSuccess', {
						announcementTitle: announcement.title,
					})
				);
			}),
			catchError((error) => {
				this.toastService.showErrorToast(this.translateService.instant('toast.deletedAnnouncementFailed', {
					announcementTitle: announcement.title,
				}));
				return throwError(error);
			})
		);
	}
}
